import { Controller } from '@hotwired/stimulus';

const POSTALCODES_URL = 'https://api.bookingexperts.nl/postcode/';
const POSTALCODE_REGEX = /\d{4}\s*[a-zA-Z]{2}/;

export default class extends Controller {
  static targets = ['postalcode', 'address', 'city', 'country'];

  connect() {
    $(this.postalcodeTarget).on('change', this.handleChange);
  }

  disconnect() {
    $(this.postalcodeTarget).off('change', this.handleChange);
  }

  handleChange = () => {
    const term = $(this.postalcodeTarget).val().replace(/\s+/g, '');

    if (!term.match(POSTALCODE_REGEX)) {
      return;
    }

    this.lookup(term).then(this.handleLookup);
  };

  lookup(term) {
    return $.getJSON(POSTALCODES_URL + term);
  }

  handleLookup = (data) => {
    if (data?.errors?.length > 0) {
      return;
    }

    $(this.addressTarget).val(data.street);
    $(this.cityTarget).val(data.city);
    $(this.countryTarget).val('NL'); // Since we only look for Dutch postal codes, if one is found we can safely set country_code to NL

    $(this.addressTarget).focus();
    $(this.addressTarget).val($(this.addressTarget).val() + ' ');
  };
}
