import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

/**
 * Performs server-side validation when an input is changed.
 *
 * @example
 * %form(data-controller="inline-validate" data-inline-validate-url="/validate")
 *   %input(type="checkbox" data-action="blur->inline-validate#validate")
 */
export default class extends Controller {
  connect() {
    this.eventQueue = [];
  }

  validate(event) {
    this.eventQueue.push(event);
    this.batchValidate();

    // Set inputs to readonly temporarily so that they won't trigger additional blur events when autofill is used.
    event.target.readonly = true;
  }

  batchValidate() {
    // Copies the event queue because it might change before the request is completed
    const debouncedEvents = [...this.eventQueue];
    this.eventQueue = [];

    $.ajax({ url: this.data.get('url'), type: 'PUT', data: $(this.element).serialize() })
      .then((response) => debouncedEvents.forEach((event) => this.handleResponseForEvent(response, event)))
      .fail(() => debouncedEvents.forEach((event) => (event.target.readonly = false)));
  }

  batchValidate = debounce(this.batchValidate, 200);

  handleResponseForEvent(response, event) {
    // Looks up the input element that triggered the validation and replaces it with
    // the corresponding part in the response body.
    const selector = '#' + event.target.id;
    const $target = $(this.element).find(selector).parent().parent();
    const $source = $(response).find(selector).parent().parent();

    $target.replaceWith($source);
  }
}
