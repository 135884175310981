import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { NextIcon, PrevIcon, renderMonthElement } from './react_dates';
import moment from '../lib/moment';

function DatePicker({ initialDate, disabled, blockedRanges, minDate, placeholder, onChange }) {
  const [isFocused, setIsFocused] = useState(false);
  const [date, setDate] = useState(initialDate && moment(initialDate).isValid() ? moment(initialDate) : null);
  const [id, _] = useState(`date-picker-${Math.random().toString().substring(2)}`);

  const handleDateChange = (date) => {
    setDate(date);
    onChange(date);
  };

  const handleFocusChange = ({ focused }) => {
    setIsFocused(focused);
  };

  const isOutsideRange = (date) => {
    if (minDate && date.isBefore(minDate)) {
      return true;
    }

    return blockedRanges.some(([startDate, endDate]) => moment(date).isBetween(startDate, endDate, 'day', '[]'));
  };

  return (
    <SingleDatePicker
      date={date}
      placeholder={placeholder}
      onDateChange={handleDateChange}
      focused={isFocused}
      onFocusChange={handleFocusChange}
      disabled={disabled}
      id={id}
      displayFormat="L"
      transitionDuration={0}
      noBorder
      block
      hideKeyboardShortcutsPanel
      verticalSpacing={4}
      numberOfMonths={1}
      isOutsideRange={isOutsideRange}
      navPrev={<PrevIcon />}
      navNext={<NextIcon />}
      renderMonthElement={renderMonthElement}
    />
  );
}

export default DatePicker;
